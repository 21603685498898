<template>
  <div class="login">
    <img class="login-back" src="../assets/login/bg_sign_in.png" alt="" />

    <div class="first">
      <div class="first-logo">
        <img src="../assets/login/logo.png" alt="" />
      </div>
      <div class="first-title">乡米网合伙人后台管理</div>
    </div>

    <div class="zero">
      <div
        class="zero-list"
        v-for="(item, index) in 2"
        :key="index"
        :class="{ zeroActive: index == zeroIndex }"
        @click="zeroClick(index)"
      >
        {{ index == 0 ? "账号密码登录" : "验证码登录" }}

        <div class="zero-list-lint" v-if="index == zeroIndex"></div>
      </div>
    </div>

    <div class="second" v-if="zeroIndex == 1">
      <van-cell-group :border="false">
        <van-field
          v-model="account"
          maxlength="11"
          placeholder="请输入登录账号"
        />
      </van-cell-group>
      <van-cell-group :border="false">
        <van-field
          v-model="password"
          placeholder="请输入验证码"
          :border="false"
          maxlength="6"
          use-button-slot
        >
          <van-button
            slot="button"
            size="small"
            class="second-button"
            @click="verificationClick"
          >
            {{ count == 0 ? "获取验证码" : count + "秒" }}
          </van-button>
        </van-field>
      </van-cell-group>
    </div>

    <div class="second" v-else>
      <van-cell-group :border="false">
        <van-field v-model="account" placeholder="请输入登录账号" />
      </van-cell-group>
      <van-cell-group :border="false">
        <van-field
          v-model="password"
          :type="passwordType"
          placeholder="请输入密码"
          :border="false"
          :right-icon="showPassword ? 'eye-o' : 'closed-eye'"
          @click-right-icon="showPassword = !showPassword"
        >
        </van-field>
      </van-cell-group>
    </div>

    <div class="bottom">
      <div class="bottom-div" @click="registerBtn">确认</div>
    </div>

    <div class="three" v-if="zeroIndex == 1" @click="passwordMet">
      忘记密码？
    </div>
  </div>
</template>

<script>
import { userLogin, postcode } from "../api/login";

export default {
  data() {
    return {
      // 账号
      account: "",
      // git 密码
      password: "",
      //------
      count: 0,
      timer: null,
      zeroIndex: 0,
      passwordType: "password",
      showPassword: false,
    };
  },
  created() {},
  watch: {
    showPassword(val) {
      if (val == true) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
  },
  methods: {
    verificationClick() {
      if (this.count != 0) {
        return;
      }

      if (this.account == "") {
        this.$toast({
          message: "请先输入手机号！",
        });
        return;
      }

      //倒计时时间
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);

        this.postcodeMet();
      }
    },
    postcodeMet() {
      let params = {
        phone: this.account,
      };
      postcode(params).then((res) => {});
    },

    registerBtn() {
      if (this.account != "" || this.password != "") {
        let params = {};

        if (this.zeroIndex == 1) {
          params = {
            phone: this.account,
            loginType: "CODE",
            code: this.password,
          };
        } else {
          params = {
            phone: this.account,
            password: this.password,
          };
        }
        userLogin(params).then((res) => {
          console.log("触发", res);
          if (res.code == 200) {
            localStorage.setItem("userInfo", JSON.stringify(res.content));
            // // 存储对应的对象 虚假token 进行判断
            localStorage.setItem("token", res.content.accessToken);
            // // 登陆成功 假设这里是后台返回的 token

            this.$router.push({
              path: res.content.status == 1 ? "/home" : "/team",
            });

            this.account = "";
            this.password = "";
          } else {
            this.$toast({
              message: res.msg,
            });
          }
        });
      }
    },

    zeroClick(index) {
      this.password = "";
      this.account = "";
      this.zeroIndex = index;
    },

    passwordMet() {
      this.$router.push({
        path: "/password?source=" + 0,
      });
    },
  },
};
</script>


<style lang="less" scoped>
.login {
  width: 100vw;
  height: 100vh;
  position: relative;
  padding-top: 64.5px;
  box-sizing: border-box;

  .login-back {
    display: flex;
    width: 100%;
    height: 211.5px;
    position: absolute;
    top: 0;
  }

  .first {
    position: relative;
    .first-logo {
      width: 80px;
      height: 80px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .first-title {
      margin: 19.5px 0 40px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 19px;
      color: #292929;
    }
  }

  .zero {
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 20px;
    padding: 0 20px;
    box-sizing: border-box;

    .zero-list {
      margin-right: 20px;
      font-size: 14px;
      font-weight: bold;
      color: #666666;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .zero-list-lint {
        height: 3px;
        background: #11d653;
        width: 52px;
        position: absolute;
        bottom: 0;
      }
    }

    .zeroActive {
      color: #11d653;
    }
  }

  .second {
    padding: 0 20px;
    box-sizing: border-box;

    /deep/ .van-cell {
      border-bottom: 0.5px solid #ccc;
      height: 55px;
    }

    /deep/ .van-cell__value {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    /deep/ .van-field__body {
      width: 100%;
    }

    .second-button {
      border-radius: 20px;
      color: #11d653;
      border: 1px solid #11d653;
      padding: 0 13px;
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36.5px;

    .bottom-div {
      width: 80vw;
      border-radius: 24px;
      height: 46px;
      background: #11d653;
      color: #fff;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .three {
    width: 80vw;
    margin: 0 auto;
    margin-top: 25px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    text-align: right;
    color: #11d653;
  }
}
</style>