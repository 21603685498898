<template>
  <div class="nav">
    <div class="nav-title">
      <div class="left" v-if="index == 0">
        <img src="../assets/login/back.png" alt="" @click="backMet" />
      </div>
      <div class="title">{{ title }}</div>

      <div class="right" v-if="add == 0">
        <img
          src="../assets/personage/add-0.png"
          alt=""
          @click="addPersonageMet"
        />
      </div>

      <div class="right" v-if="history == 0" @click="historyMet">
        历史缴纳记录
      </div>
      
    </div>
    <div class="nav-lint"></div>
  </div>
</template>

<script>
export default {
  props: ["title", "index", "add", "orderId", "price", "history"],
  data() {
    return {};
  },
  methods: {
    closeMet() {
      this.$router.go(-1);
    },
    backMet() {
      this.$router.go(-1);
    },
    addPersonageMet() {
      this.$router.push({
        path: "/personage-bank-sign?price=" + this.price,
      });
    },

    // 历史记录
    historyMet() {
      this.$router.push({
        path: "/personage-pertnre-list-history"
      });
    }

  },
};
</script>

<style lang="less" scoped>
.nav {
  .nav-title {
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    position: fixed;
    z-index: 10;
    top: 0;
    background: #ededed;

    line-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: #222222;
    }

    .left {
      position: absolute;
      left: 10px;
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .right {
      position: absolute;
      right: 10px;
      display: flex;
      align-items: center;

      font-size: 12px;

      img {
        width: 18px;
        height: 18px;
        margin-left: 10px;
      }
    }
  }

  .nav-lint {
    width: 100%;
    height: 50px;
  }
}
</style>