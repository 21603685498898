import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vant from "vant"
import 'vant/lib/index.css';
Vue.use(Vant)


Vue.config.productionTip = false
import * as echarts from 'echarts' //引入echarts
Vue.prototype.$echarts = echarts //引入组件

import 'element-ui/lib/theme-chalk/index.css'
import element from './plugins/element'
Vue.use(element)

import axios from "axios";
import qs from 'qs';
Vue.prototype.$qs = qs;
Vue.prototype.$axios = axios;
Vue.prototype.$http = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
