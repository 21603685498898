import request from './request'

// 申请合伙人界面 - 钱包接口
export function getWalletInfo(params) {
    return request.get(`/api/collaborate/getWalletInfo`, { params })
}

// 申请合伙人界面 - 生成订单号
export function getOrderNum(params) {
    return request.get(`/api/new_pay/getOrderNum`, { params })
}

// 申请合伙人界面 - 保证金充值
export function postEarnest_pay(data) {
    return request.post(`/api/collaborate/earnest_pay`, data)
}

// 申请合伙人界面 - 订单查询状态请求订单完成
export function getIsRecharge(params) {
    return request.get(`/api/new_pay/getIsRecharge`, { params })
}

// 申请合伙人界面 - 申请合伙人
export function postApplyingPartners(data) {
    return request.post(`/api/collaborate/applyingPartners`, data)
}


// 申请合伙人界面 押金本机提款申请
export function postDepositMoneyWithraw(data) {
    return request.post(`/api/collaborate/depositMoneyWithraw`, data)
}

// 申请合伙人界面 押金本机提款申请全部  全部退款
export function postDepositAllMoneyWithraw(data) {
    return request.post(`/api/collaborate/depositAllMoneyWithraw`, data)
}

// 申请合伙人界面 支持的银行
export function getqueryBankListBank(params) {
    return request.get(`/api/new_pay/queryBankList`, { params })
}

// 申请合伙人界面 获取签约银行
export function getQuickBankBank(params) {
    return request.get(`/api/collaborate/getQuickBank`, { params })
}

// 申请合伙人界面 协议签约
export function postsignProtocolBank(data) {
    return request.post(`/api/collaborate/signProtocol`, data)
}

// 申请合伙人界面 确认协议签约
export function postsignConfirmBank(data) {
    return request.post(`/api/collaborate/signConfirm`, data)
}


// 申请合伙人接口 支付金额
export function postprotocolPayConfirmBank(data) {
    return request.post(`/api/collaborate/protocolPayConfirm`, data)
}

// -------聚合支付 微信支付 生成二维码图片
export function getCreate_imageByUrl(params) {
    return request.get(`/api/login/create_imageByUrl`, { params })
}


// -------聚合支付 银联在线  银行支付
export function postUnionpayEarnest_pay(data) {
    return request.post(`/api/new_pay/earnest_pay`, data)
}






