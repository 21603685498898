import {
    Pagination,
    Loading,
    Message,
    Button,
    Carousel,
    CarouselItem,
    InfiniteScroll,
    Select,
    TimeSelect,
    Option,
    OptionGroup,
    Badge,
    Input,
    InputNumber,
    Icon,
    Tag,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Dialog,
    Form,
    FormItem,
    Cascader,
    Radio,
    RadioGroup,
    RadioButton,
    Collapse,
    CollapseItem,
    PageHeader,
    Popover,
    Steps,
    Step,
    Upload,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Image,
    Autocomplete,

} from 'element-ui';

const element = {
    install: function (Vue) {
        Vue.use(Popover);
        Vue.use(Pagination);
        Vue.use(Loading.directive);
        Vue.use(Button);
        Vue.use(Carousel);
        Vue.use(CarouselItem);
        Vue.use(InfiniteScroll);
        Vue.use(Select);
        Vue.use(TimeSelect);
        Vue.use(Option);
        Vue.use(OptionGroup);
        Vue.use(Badge);
        Vue.use(Input);
        Vue.use(InputNumber);
        Vue.use(Icon);
        Vue.use(Tag);
        Vue.use(Checkbox);
        Vue.use(CheckboxButton);
        Vue.use(CheckboxGroup);
        Vue.use(Dialog);
        Vue.use(Form);
        Vue.use(FormItem);
        Vue.use(Cascader);
        Vue.use(Radio);
        Vue.use(RadioGroup);
        Vue.use(RadioButton);
        Vue.use(Collapse);
        Vue.use(CollapseItem);
        Vue.use(PageHeader);
        Vue.use(Steps);
        Vue.use(Step);
        Vue.use(Upload);
        Vue.use(Menu);
        Vue.use(Submenu);
        Vue.use(MenuItem);
        Vue.use(MenuItemGroup);
        Vue.use(Image);
        Vue.use(Autocomplete);

        Vue.prototype.$loading = Loading.service;
        Vue.prototype.$message = Message;
    }
}

export default element