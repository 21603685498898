import request from './request'

// 用户登录
export function userLogin(data) {
    return request.post(`api/collaborate/login`, data)
}

// 用户退出
export function userQuit(data) {
    return request.post(`api/collaborate/outlogin`, data)
}

// 发送验证码
export function postcode(data) {
    return request.post(`api/login/send_code`, data)
}

// 找回密码
export function postretrieve(data) {
    return request.post(`api/collaborate/modifyPassword`, data)
}


