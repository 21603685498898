<template>
  <div id="app">
    <div class="download-layer" v-show="showTip == true">
      <img
        class="download-layer-img"
        src="./assets/personage/Montmorillonite.png"
        alt=""
        srcset=""
      />
    </div>

    <router-view />
    <div class="bottom" v-if="appShow == true">
      <tab-bar :index="appIndex" ref="tabbarRef"></tab-bar>
    </div>
  </div>
</template>

<script>
var that = this;

import tabBar from "./components/tab-bar.vue";

export default {
  components: {
    tabBar,
  },
  data() {
    return {
      appShow: false,
      // 下载蒙层
      showTip: false,
      appIndex: 0,
      tabList: [
        {
          path: "/",
        },
        {
          path: "/home",
        },
        {
          path: "/earnings",
        },
        {
          path: "/order",
        },
        {
          path: "/team",
        },
        {
          path: "/personage",
        },
      ],
    };
  },
  created() {
    //平台、设备和操作系统
    var system = {
      win: false,
      mac: false,
      xll: false,
    };
    //检测平台
    var p = navigator.platform;
    system.win = p.indexOf("Win") == 0;
    system.mac = p.indexOf("Mac") == 0;
    system.x11 = p == "X11" || p.indexOf("Linux") == 0;

    if (system.win || system.mac || system.xll) {
      //PC端
    window.location.href = "https://partner.xmw1688.com/#/";
    } else {
      //移动端跳转的链接
    }

    this.APPshow();

    this.createdfirstStop();
  },
  watch: {
    $route(to, from) {
      try {
        this.tabList.forEach((item, index) => {
          if (to.path == item.path) {
            this.appIndex = index;
            console.log(index);
            this.appShow = true;
            this.$refs.tabbarRef.getWalletInfoMet();
            throw Error();
          } else if (to.path != item.path) {
            this.appIndex = "";
            this.appShow = false;
          }
        });
      } catch (error) {}
    },
  },
  mounted() {
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    APPshow() {
      var ua = navigator.userAgent.toLowerCase();
      console.log(navigator.userAgent.toLowerCase());

      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.showTip = true;
      } else if (ua.indexOf(" qq") > -1 && ua.indexOf("mqqbrowser") > 0) {
        //qq内置浏览器
        this.showTip = true;
      } else {
        this.showTip = false;
      }
    },

    //-----------------------
    createdfirstStop() {
      this.$axios.get("https://baseurl.xmw1688.com/").then(
        (res) => {
          let str = res.data.baseurl;
          localStorage.setItem("connector", str);
        },
        (err) => {
          this.createdSecondStop()
        }
      );
    },

    // 第二
    createdSecondStop() {
      this.$axios.get("https://baseurl.dwhk918.cn/").then(
        (res) => {
          let str = res.data.baseurl;
          localStorage.setItem("connector", str);
        },
        (err) => {
          this.createdThreeStop()
        }
      );
    },
    // 第三
    createdThreeStop() {
      this.$axios.get("https://baseurl.yuncangxiaodian.com/").then(
        (res) => {
          let str = res.data.baseurl;
          localStorage.setItem("connector", str);
        },
        (err) => {
          let str = "https://front.xmw1688.com/api";
          localStorage.setItem("connector", str);
        }
      );
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}



body {
  padding: 0;
  margin: 0;
  // touch-action: none; /* 禁止页面缩放 */
}

.el-date-range-picker {
  z-index: 99999 !important;
}

.download-layer {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  .download-layer-img {
    width: 100%;
    height: 100%;
    z-index: 10;
    left: 0;
    top: 0;
  }
}

</style>
