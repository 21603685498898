<template>
  <div class="tab">
    <div class="first">
      <van-tabbar v-model="active" active-color="#11D653">
        <van-tabbar-item
          v-for="(item, index) in tabList"
          :key="index"
          @click="barpathMet(item)"
        >
          <template #icon="props">
            <img :src="props.active ? item.active : item.inactive" />
          </template>

          <div
            class="first-title"
            :style="{ color: active == index ? '#11d653' : '' }"
          >
            {{ item.title }}
          </div>
        </van-tabbar-item>
      </van-tabbar>
    </div>

    <div class="first-lint"></div>
  </div>
</template>

<script>
import { getWalletInfo } from "../api/pay";

export default {
  comments: ["index"],

  data() {
    return {
      active: this.index,
      tabList: [],
    };
  },

  created() {
    // 获取钱包信息
    this.getWalletInfoMet();
  },
  methods: {
    getWalletInfoMet() {
      let params = {};

      getWalletInfo(params).then((res) => {
        if (res.code == 200) {
          // 如果用户不是合伙人的时候只保留两个
          if (res.content.collaborateStatus != 1) {
            this.tabList = [
              {
                title: "团队",
                path: "/team",
                active: require("../assets/tabbar/ic_our1.png"),
                inactive: require("../assets/tabbar/ic_our2.png"),
              },
              {
                title: "我的",
                path: "/personage",
                active: require("../assets/tabbar/ic_time1.png"),
                inactive: require("../assets/tabbar/ic_time2.png"),
              },
            ];

            this.tabList.forEach((item, index) => {
              if ( this.$route.path == item.path) {
                this.active = index;
              }
            });


          } else {
            this.tabList = [
              {
                title: "首页",
                path: "/home",
                active: require("../assets/tabbar/ic_home1.png"),
                inactive: require("../assets/tabbar/ic_home2.png"),
              },
              {
                title: "收益",
                path: "/earnings",
                active: require("../assets/tabbar/ic_income1.png"),
                inactive: require("../assets/tabbar/ic_income2.png"),
              },
              {
                title: "订单",
                path: "/order",
                active: require("../assets/tabbar/ic_list1.png"),
                inactive: require("../assets/tabbar/ic_list2.png"),
              },
              {
                title: "团队",
                path: "/team",
                active: require("../assets/tabbar/ic_our1.png"),
                inactive: require("../assets/tabbar/ic_our2.png"),
              },
              {
                title: "我的",
                path: "/personage",
                active: require("../assets/tabbar/ic_time1.png"),
                inactive: require("../assets/tabbar/ic_time2.png"),
              },
            ];

            let currentRoute = this.$router.currentRoute.fullPath;

            this.tabList.forEach((item, index) => {
              if (currentRoute == item.path) {
                this.active = index;
                console.log("触发", this.active);
              }
            });
          }
        } else {
          // // 退出登陆 清除用户资料
          localStorage.clear();
          // 重设路由
          this.$router.replace({
        path: "/login",
      });
        }
      });
    },

    barpathMet(item) {
      this.$router.push({
        path: item.path,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tab {
  .first {
    .first-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 10.5px;
      margin-top: 2px;
    }
  }

  .first-lint {
    height: 70px;
  }
}
</style>