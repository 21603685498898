import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isShowLoading: false, // 全局 loading
    // 判断当前用户消息是否已经全部读了
    isRead: 0,
  },
  getters: {
  },
  mutations: {
    setLoading(state, isShowLoading) {
      state.isShowLoading = isShowLoading
    },
    // 获取用户消息全部是否已读
    isReadBtn(state, id) {
      // localStorage.setItem('isReadIndex', res.data.content.isRead)
      state.isRead = id
    }
  },
  actions: {
  },
  modules: {
  }
})
