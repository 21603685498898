<template>
  <div class="password">
    <navBar :title="sourceIndex == 0 ? '找回密码' : '修改密码'" :index="0"></navBar>

    <div class="first">
      <van-cell-group :border="false">
        <van-field v-model="account" placeholder="请输入登录账号" />
      </van-cell-group>
      <van-cell-group :border="false">
        <van-field
          v-model="verification"
          placeholder="请输入验证码"
          :border="false"
          maxlength="6"
          use-button-slot
        >
          <van-button
            slot="button"
            size="small"
            class="second-button"
            @click="verificationClick"
          >
            {{ count == 0 ? "获取验证码" : count + "秒" }}
          </van-button>
        </van-field>
      </van-cell-group>
      <van-cell-group :border="false">
        <van-field v-model="password" placeholder="请确认新密码" />
      </van-cell-group>
      <van-cell-group :border="false">
        <van-field v-model="passwordSecond" placeholder="请输入新密码" />
      </van-cell-group>
    </div>

    <div class="bottom">
      <div class="bottom-div" @click="registerBtn">确认</div>
    </div>
  </div>
</template>

<script>
import { postretrieve, postcode } from "../api/login";

import navBar from "./navbar.vue";
export default {
  components: {
    navBar,
  },
  data() {
    return {
      // 账号
      account: "",
      verification: "",
      password: "",
      passwordSecond: "",
      //------
      count: 0,
      timer: null,
      //--------
      sourceIndex: "",
    };
  },
  created() {
    this.sourceIndex = this.$route.query.source;
  },
  methods: {
    verificationClick() {
      if (this.count != 0) {
        return;
      }

      if (this.account == "") {
        this.$toast({
          message: "请先输入手机号！",
        });
        return;
      }

      //倒计时时间
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);

        this.postcodeMet();
      }
    },
    postcodeMet() {
      let params = {
        phone: this.account,
      };
      postcode(params).then((res) => {});
    },

    registerBtn() {
      if (this.password != this.passwordSecond) {
        this.$toast({
          message: "二次确认密码错误！",
        });
        return;
      }
      if (this.account != "" || this.password != "") {
        let params = {
          phone: this.account,
          code: this.verification,
          newPassword: this.password,
        };

        postretrieve(params).then((res) => {
          if (res.code == 200) {
            this.$toast({
              message: "修改成功！",
            });

            setTimeout(() => {
              // // 退出登陆 清除用户资料
              localStorage.clear();
              // 重设路由
              this.$router.replace({
        path: "/login",
      });
              
            }, 1000);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.password {
  .first {
    padding: 0 10px;
    box-sizing: border-box;

    /deep/ .van-cell {
      border-bottom: 0.5px solid #ccc;
      height: 55px;
    }

    /deep/ .van-cell__value {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    /deep/ .van-field__body {
      width: 100%;
    }

    .second-button {
      border-radius: 20px;
      color: #11d653;
      border: 1px solid #11d653;
      padding: 0 13px;
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36.5px;

    .bottom-div {
      width: 80vw;
      border-radius: 24px;
      height: 46px;
      background: #11d653;
      color: #fff;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>