import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../components/login.vue'
import password from '../components/password.vue'

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}



const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/password',
    name: 'password',
    component: password
  },
  // 合伙人后台 -> 首页
  {
    path: '/',
    name: 'home',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/home/home.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/home/home.vue')
  },
  // 合伙人后台 -> 收益
  {
    path: '/earnings',
    name: 'earnings',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/earnings/earnings.vue')
  },
  // 合伙人后台 -> 收益列表
  {
    path: '/earnings-details',
    name: 'earningsDetails',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/earnings/earnings-detail.vue')
  },
  // 合伙人后台 -> 提现列表
  {
    path: '/withdraw-details',
    name: 'withdrawDetail',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/earnings/withdraw-detail.vue')
  },
  // 合伙人后台 -> 提现界面
  {
    path: '/withdraw-deposit',
    name: 'withdraDeposit',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/earnings/withdraw-deposit.vue')
  },
  // 合伙人后台 -> 订单
  {
    path: '/order',
    name: 'order',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/order/order.vue')
  },
  // 合伙人后台 -> 团队
  {
    path: '/team',
    name: 'team',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/team/team.vue')
  },
  // 合伙人后台 -> 团队列表
  {
    path: '/team-list',
    name: 'teamList',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/team/team-list.vue')
  },
  // 合伙人后台 -> 个人
  {
    path: '/personage',
    name: 'personage',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/personage/personage.vue')
  },
  // 合伙人后台 -> 个人信息
  {
    path: '/personage-message',
    name: 'personageMessage',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/personage/personage-list.vue')
  },
  // 合伙人后台 -> 保证金
  {
    path: '/personage-partner',
    name: 'personagePartner',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/personage/personage-partner.vue')
  },
  // 合伙人后台 -> 缴纳保证金
  {
    path: '/personage-cash-deposit',
    name: 'personageCashdeposit',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/personage/personage-cash-deposit.vue')
  },
    // 合伙人后台 -> 网银在线
    {
      path: '/personage-cash-Internetbank',
      name: 'personageCashInternetbank',
      meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
      component: () => import('../views/personage/personage-cash-Internetbank.vue')
    },
  // 合伙人后台 -> 支付成功
  {
    path: '/personage-cash-success',
    name: 'personageCashsuccess',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/personage/personage-cash-success.vue')
  },
  //--------银行卡
  // 我的银行卡列表
  {
    path: '/personage-bank',
    name: 'personageBank',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/personage/personage-bank.vue')
  },
  // 银行卡-签约银行卡
  {
    path: '/personage-bank-sign',
    name: 'personageBankSign',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/personage/personage-bank-sign.vue')
  },
   // 缴纳保证金列表记录
   {
    path: '/personage-pertnre-list',
    name: 'personagePertnreList',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/personage/personage-pertnre-list.vue')
  },
  {
    path: '/personage-pertnre-list-history',
    name: 'personagePertnrehistoryList',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/personage/personage-pertnre-list-history.vue')
  },
  {
    path: '/personage-deposit',
    name: 'personageDeposit',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/personage/personage-deposit.vue')
  },
  //----------提现区域银行卡列表
  {
    path: '/withdraw-deposit-bank',
    name: 'withdrawDepositBank',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/earnings/withdraw-deposit-bank.vue')
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.matched.some(res => res.meta.isLogin)) {//判断是否需要登录
    if (localStorage['token']) {
      next();
    } else {
      next({
        path: "/login"
      });
    }
  } else {
    next()
  }
})

export default router
